import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { Head } from "../components/blog/Head"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import { Disqus, CommentCount } from "gatsby-plugin-disqus"
import { getDisqusConfig } from "../components/utils/Blog"
import { Author } from "../components/blog/Author"
import Newsletter from "../components/forms/Newsletter"
import highlightCode from "../components/utils/HighlightCode"

// props
interface Props {
  data: {
    post: {
      title: string
      category: string
      disqusId: string
      fields: {
        path: string
        publishedAt: string
      }
      body: {
        childMarkdownRemark: {
          html: any
        }
      }
    }
    author: {
      title: string
      slug: string
      inactive: boolean
    }
  }
}

// markup
const BlogTemplate = (props: Props) => {
  React.useEffect(() => {
    highlightCode()
  })
  const blogInfo = props.data.post
  const author = props.data.author
  const disqusConfig = getDisqusConfig(props.data.post)

  return (
    <Layout
      seo={{
        title: blogInfo.title,
        hasNoSpring: true,
      }}
      hideNewsletter={true}
      className="blog"
    >
      <div className="container mb-6">
        <Head />
        <div className="columns content pt-5 pb-6">
          <div className="column is-9 pr-6">
            <div className="blog-post mb-4" style={{ borderTop: 0 }}>
              <h1
                style={{ lineHeight: "3rem" }}
                className="has-text-weight-semibold is-size-3"
              >
                {blogInfo.title}
              </h1>
              <div className="meta">
                <Link
                  to={`/blog/category/${blogInfo.category?.toLocaleLowerCase()}`}
                >
                  {blogInfo.category}
                </Link>
                {" | "}
                <Author author={author} />
                {` | `} {blogInfo.fields.publishedAt}
                {` | `}
                <Link to={`#disqus_thread`}>
                  <CommentCount config={disqusConfig} placeholder={"..."} />
                </Link>
              </div>
            </div>

            <HTMLContent
              content={blogInfo.body?.childMarkdownRemark?.html}
              className={"markdown"}
            ></HTMLContent>

            <Disqus config={disqusConfig} />
          </div>
          <div className="column is-3">
            <div className="has-background-light px-5 py-5 blog-sidebar">
              <h2 className="is-size-4 has-text-weight-bold">
                Get the Spring newsletter
              </h2>
              <Newsletter variant="blog" />
            </div>
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default BlogTemplate

// graphql queries
export const pageQuery = graphql`
  query BlogTemplate($id: String, $author: String) {
    post: contentfulBlogPost(id: { eq: $id }) {
      title
      category
      disqusId
      fields {
        path
        publishedAt(formatString: "MMMM DD, YYYY")
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    author: contentfulMember(id: { eq: $author }) {
      title
      slug
      inactive
    }
  }
`
